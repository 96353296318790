import { useEffect, useState } from "react";
import { db, auth, storage } from '../firebase';
import {getDocs, collection, addDoc, deleteDoc, doc, updateDoc} from 'firebase/firestore';
import { ref, uploadBytes } from "firebase/storage";
import { useNavigate } from 'react-router-dom';

const CreatePostForm = () => {
  /*
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Save the new blog post to Firestore
      const blogPost = { title, content };
      const firestore = firebase.firestore();
      await firestore.collection('Blogs').add(blogPost);

      // Reset the form fields
      setTitle('');
      setContent('');

      // Optional: Show a success message to the user
      alert('Blog post created successfully!');
    } catch (error) {
      // Handle any errors that may occur during Firestore operation
      console.error('Error creating blog post:', error);
      // Optional: Show an error message to the user
      alert('Error creating blog post. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <textarea
        placeholder="Content"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      ></textarea>
      <button type="submit">Create Post</button>
    </form>
  );*/
};

export default CreatePostForm;
