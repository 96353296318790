import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CreatePostForm from './components/CreatePostForm';
import BlogPosts from './components/BlogPosts';
import PostDetails from './components/PostDetails';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<BlogPosts />}/>
          <Route path="/create" element={<CreatePostForm />}/>
          <Route path="/post/:id" element={<PostDetails />}/>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
