// BlogPosts.js

import React from 'react';
import DisplayBlogPosts from './DisplayBlogPosts';
import './BlogPosts.css';

const BlogPosts = () => {
  return (
    <div className="blog-posts-container">
      <h1 className="blog-title">Isaac Ezequiel Salas</h1>
      <p className="blog-introduction">Welcome to my Portfolio Website</p>
      <DisplayBlogPosts className="display-blog-posts" />
    </div>
  );
};

export default BlogPosts;
